.sidebar-widget {
  margin-bottom: 3rem; }

.widget-cta .text {
  margin-bottom: 1rem;
  text-align: center; }
  .widget-cta .text span {
    display: block;
    line-height: 1;
    font-size: 1.5rem; }
  .widget-cta .text .big {
    font-size: 2.5rem;
    font-weight: 600;
    color: #2962ff; }

.widget-cta form .btn {
  margin-top: .5rem; }
